import { chakra, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

const COUNTER_OVERLOAD = 50;

type Props = {
  count?: number | null;
  isActive?: boolean;
};

const TabCounter = ({ count, isActive }: Props) => {
  // const zeroCountColor = useColorModeValue('blackAlpha.400', 'whiteAlpha.400');
  const countColor = useColorModeValue(
    '#1A1A1A99',
    isActive ? '#EEEEEE66' : '#FFFFFF'
  );

  if (count === undefined || count === null) {
    return null;
  }

  return (
    <chakra.span color={countColor} ml={1} {...getDefaultTransitionProps()}>
      {count > COUNTER_OVERLOAD ? `${COUNTER_OVERLOAD}+` : count}
    </chakra.span>
  );
};
export default TabCounter;
